<template lang="pug">
include ../pug/svg
section#calls
	//-pre modal: {{modal.fields}}
	//-pre previewCall: {{previewCall}}
	div.ctrl
		Search(v-model:search="search")
		//-Filter(id="statusFilter" label="Status" title="Status" :options="statusFilters" v-model:selected="statusFilter" :clear="false")

		//-button.but.circ.plus.text(v-if="canHost(user)" type="button" @click="addCall()")
		button.but.circ.plus.text(type="button" @click="addCall()")
			span Add Call
			+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
		
		button.but.circ.plus.text(type="button" @click="createLicencedUser(6)")
			span Add Licence dUser
			+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
			
		button.but.circ.plus.text(type="button" @click="deleteLicencedUser(2)")
			span Bin Licence dUser
			+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
			
	div.wrap
		ul.list
			li.person(v-for="(call, index) in filteredCalls" :key="call.id" :call="call" :index="index" @mouseenter="previewIndex=index" :class="{active:previewIndex===index}")
				picture.user.online(v-if="call.host.profileUrl")
					img(:src="call.host.profileUrl" width="40" height="40" :alt="call.host.fullName")
				span.user(v-else :class="{online:call.host.loggedIn}") {{call.host.initials}}
				hgroup
					h2 {{call.topic}}
					h3 {{formatStartTime(call.startDateTime)}}
				a.arrow View Details
					+svg(svg-filename='iconArrow')
				//-button.but.pri(@click="getSignature(call)") Join Meeting
				div.butmore
					a(role="button") Start Call
					button.but(type="button" aria-haspopup="true" aria-controls="" @mouseenter="showTint" @mouseleave="hideTint") More
						+svg(svg-filename="iconMore" aria-hidden="true" alt="More icon")
					nav.menu(@mouseenter="showTint" @mouseleave="hideTint")
						div.bar
							+svg(svg-filename="iconMore" aria-hidden="true")
							button.but(type="button") Close
								+svg(svg-filename="iconClose")
						ul
							li
								a(role="button" @click="editCall(index)") Edit Call
							li
								a.bin(role="button") Cancel Call
									+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")

		div.preview(v-if="previewCall")
			div.head
				h4 {{previewCall.role===1?'Host':'Participant'}}
				div.butmore(v-if="previewCall.role===1")
					//-router-link(:to="{name:'ManagePeopleUserProfile', params:{userId:user.id}}") Edit Profile
					button.but(@click="getSignature") Start Call
					button.but(type="button" aria-haspopup="true" aria-controls="" @mouseenter="showTint" @mouseleave="hideTint") More
						+svg(svg-filename="iconMore" aria-hidden="true" alt="More icon")
				button.but.pri(v-else @click="getSignature(previewCall)" :disabled="callActive") Join Call
			div.body
				hgroup
					h3 {{previewCall.topic}}
					h4 {{previewCall.host.fullName}}
				table
					tr
						th Agenda
						td {{previewCall.agenda}}
					//-tr
						th Call ID
						td {{previewCall.meetingNumber}}
					tr
						th Start Time
						td {{formatStartTime(previewCall.startDateTime)}}
					tr
						th Duration
						td {{previewCall.duration}}
				button(@click="endCall()") End Meeting (host only)
				button(@click="getAttendees()") Attendees
				button(@click="leaveCall()") Leave Call

	div.modal.center(:class="{active:modal.show}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span(v-if="modal.add") Add Call
			span(v-else) Edit Call
			button.but(type="button" @click="toggleModal(false)") Close
				+svg(svg-filename="iconClose")
		div.tog
			button.but(type="button" :class="{on:!modal.showAttendees}" @click="modal.showAttendees=false") Details
			button.but(type="button" :class="{on:modal.showAttendees}" @click="modal.showAttendees=true") Attendees

		div.body
			Form(ref="formCall" :validation-schema="callSchema" v-slot="{ errors, isSubmitting, values, meta }" @submit="saveCall")
				div.slider(:class="{show:modal.showAttendees}")
					div.detail
						div.field.grow
							Field( v-model="modal.fields.topic" name="topic" type="text" placeholder="Unsupplied" autocomplete="off" :validateOnInput="false" required)
							label Topic
							transition(name="fade")
								ErrorMessage.err(name="topic" as="span")
						div.field.grow
							Field(v-model="modal.fields.agenda" name="agenda" as="textarea" placeholder="Unsupplied" autocomplete="off" :validateOnInput="false" required)
							label Agenda
							transition(name="fade")
								ErrorMessage.err(name="agenda" as="span")
						fieldset.fgroup
							div.field.c4
								Field(v-model="modal.fields.startDate" name="startDate" type="date" placeholder="Unsupplied" autocomplete="off" :validateOnInput="false" required)
								label Start Date
								transition(name="fade")
									ErrorMessage.err(name="startDate" as="span")
							div.field.c4
								Field(v-model="modal.fields.startTime" name="startTime" type="time" placeholder="Unsupplied" autocomplete="off" :validateOnInput="false" required)
								label Start Time
								transition(name="fade")
									ErrorMessage.err(name="startTime" as="span")
							div.field.c4
								Field(v-model="modal.fields.duration.value" name="duration" as="select" placeholder="Unsupplied" autocomplete="off" :validateOnInput="false" v-slot="{value}" :class="{empty:!values.duration}" required)
									option(v-for="(durationOpt, index) in durationOpts" :value="durationOpt.value" :selected="value&&value===durationOpt.value") {{durationOpt.option}}
								label Duration
								+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
								transition(name="fade")
									ErrorMessage.err(name="duration" as="span")
							div.field.c4
								input(type="checkbox" v-model="modal.fields.muteOnEntry" value="true")
								label Mute on entry
								span.on(v-if="modal.fields.muteOnEntry") Yes
								span.off(v-else) No
					div.attendee
						div.ctrl
							Search(v-model:search="modal.search")
							Filter(id="groupFilter" label="Group" title="Groups" :options="modal.groupFilters" v-model:selected="modal.groupFilter")
						div.wrap
							h6
								button.but.circ.dblfwdarr.text(type="button" @click="assignUsers(unassignedUsers)" :disabled="!unassignedUsers.length")
									span Assign All
									+svg(svg-filename="iconDoubleArrow" aria-hidden="true" alt="Assign all icon")
								span Unassigned ({{unassignedUsers.length}})
							ul.list.unassigned
								li.person(v-for="(unassignedUser, index) in unassignedUsers" :key="unassignedUser.id" :user="unassignedUser" :index="index")
									picture.user(v-if="unassignedUser.profileUrl" :class="{online:unassignedUser.online}")
										img(:src="unassignedUser.profileUrl" width="40" height="40" :alt="unassignedUser.fullName")
									span.user(v-else :class="{online:unassignedUser.online}") {{unassignedUser.initials}}
									hgroup
										h2 {{unassignedUser.fullName}}
										h3 {{unassignedUser.publishStatus}} {{unassignedUser.userOrganisations[0].role.name}}
									button.but.circ.fwdarr(type="button" @click="assignUsers(unassignedUser)")
										span Assign User
										+svg(svg-filename="iconArrow" aria-hidden="true" alt="Assign icon")
							h6
								span Assigned ({{assignedUsers.length}})
								button.but.circ.dblbckarr.text.flip(type="button" @click="unassignUsers(assignedUsers)" :disabled="!assignedUsers.length")
									span Unassign All
									+svg(svg-filename="iconDoubleArrow" aria-hidden="true" alt="Unassign all icon")
							ul#assigned.list
								li.person(v-for="(assignedUser, index) in assignedUsers" :key="assignedUser.id" :user="assignedUser" :index="index")
									picture.user(v-if="assignedUser.profileUrl" :class="{online:assignedUser.online}")
										img(:src="assignedUser.profileUrl" width="40" height="40" :alt="assignedUser.fullName")
									span.user(v-else :class="{online:assignedUser.online}") {{assignedUser.initials}}
									hgroup
										h2 {{assignedUser.fullName}}
										p.host(v-if="isHost(assignedUser)") Host
										Filter(v-else-if="canHost(assignedUser)" title="Call Role" :options="callRoleOpts" :selected="getCallRole(assignedUser)" @update:selected="setCallRole($event, assignedUser)" :clear="false" :setTint="false")
									button.but.circ.bckarr(v-if="!isHost(assignedUser)" type="button" @click="unassignUsers(assignedUser)")
										span Unassign User
										+svg(svg-filename="iconArrow" aria-hidden="true" alt="Unassign icon")
				div.foot
					span.req Required
					button.but.pri.spinner(type="submit" :class="{spin:isSubmitting}" :disabled="!meta.valid||isSubmitting||!meta.touched||!modal.fields.attendees.length") Save
					button.but.sec(type="button" @click="toggleModal(false)") Cancel
</template>

<script>
import dayjs from 'dayjs';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import CallService from '../services/CallService';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import Filter from '../components/Filter';
import Search from '../components/Search';

export default {
	name: 'Calls',
	components: {
		Form,
		Field,
		ErrorMessage,
		Search,
		Filter,
	},
	data () {
		const req = 'Required';
		const callSchema = Yup.object().shape({
			topic: Yup.string().required(req).nullable(),
			agenda: Yup.string().required(req).nullable(),
			startDate: Yup.string().required(req).nullable(),
			startTime: Yup.string().required(req).nullable(),
		});

		const statusFilterOpts = [
			{value: 'upcoming', option: 'Upcoming'},
			{value: 'archived', option: 'Archived'},
		];

		const callRoleOpts = [
			{value: 'attendee', option: 'Attendee'},
			{value: 'alt_host', option: 'Alternative Host'},
//			{value: 'host', option: 'Host'},
		];

		const durationOpts = [
			{value: 10, option: '10 Minutes'},
			{value: 20, option: '20 Minutes'},
			{value: 30, option: '30 Minutes'},
			{value: 40, option: '40 Minutes'},
			{value: 50, option: '50 Minutes'},
			{value: 60, option: '1 Hour'},
			{value: 90, option: '1.5 Hours'},
			{value: 120, option: '2 Hours'},
		];

		return {
			callSchema,
			search: null,
			statusFilters: statusFilterOpts,
			statusFilter: statusFilterOpts[0], // default
			callRoleOpts: callRoleOpts,
			callRoleDef: callRoleOpts[0], // default
			durationOpts: durationOpts,
			previewIndex: 0,
			//
//sdkKey: VUE_APP_ZOOM_WEBSDK_KEY,
sdkKey: 'FwCD430sIG4D7pl8jplwF7xSoPSwwZdydJNi', // IS THIS SAFE TO EXPOSE?? WILL NEED TO GET FROM DATABASE AGAINST ORGANISATION
			client: ZoomMtgEmbedded.createClient(), // returns EmbeddedClient
			callActive: false,
			// add/edit call modal
			modal: {
				show: false,
				search: null,
				groupFilter: null,
				fields: {
					topic: null,
					agenda: null,
					startDate: null,
					startTime: null,
					duration: durationOpts[3], // 40 mins
					muteOnEntry: false,
					attendees: [],
				},
				add: true, // add or edit mode
				showAttendees: false, // details / attendees toggle
			},
		}
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: 'Upcoming Calls',
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},
		});

		this.refreshCalls();
	},
	computed: {
		user() {
			return this.$store.state.auth.user;
		},
		licensedUsers() {
			return this.$store.state.calls.licensedUsers;
		},
		calls() {
			return [...this.$store.state.calls.meetings];
		},
		filteredCalls() {
			// status filter
			//const f1 = (this.statusFilter) ? this.calls.filter(c => c.endDatetime !== '') : this.calls;
			const f1 = this.calls;

			// serch term filter
			return (this.search) ? f1.filter(c => c.topic.toLowerCase().includes(this.search.toLowerCase())) : f1;
		},
		previewCall() {
			return this.filteredCalls[this.previewIndex];
		},
		users() {
			// organisation users a-z
			const users = this.$store.state.users.users.map(u => {
				// add new attribute
				return {...u, callRole: this.callRoleDef.value};
			});

			return users.sort((a, b) => {
				return a.fullName.localeCompare(b.fullName);
			});
		},
		groupFilters() {
			const groups = this.$store.state.gui.organisation.organisationGroups;
			// map value/option pairs to group data
			return groups.map(g => {
				return { value: g.id, option: g.name };
			});
		},
		filteredUsers() {
			// remove archived users
			let activeUsers = this.users.filter(u => u.userOrganisations[0].status !== 'archived');

			// apply user group filter
			const selected = this.modal.groupFilter?.value || null;
			activeUsers = (selected) ? activeUsers.filter(u => u.userOrganisations[0].userOrganisationGroups.map(g => g.organisationGroupId).includes(selected)) : activeUsers;

			// serch term filter
			return (this.modal.search) ? activeUsers.filter(u => u.fullName.toLowerCase().includes(this.modal.search.toLowerCase())) : activeUsers;
		},
		assignedUsers() {
			const assignedUserIds = this.modal.fields.attendees.map(u => u.id) || [];
			return this.filteredUsers.filter(u => assignedUserIds.includes(u.id));
		},
		unassignedUsers() {
			const assignedUserIds = this.modal.fields.attendees.map(u => u.id) || [];
			return this.filteredUsers.filter(u => !assignedUserIds.includes(u.id));
		},
	},
	methods: {
		async createLicencedUser(userId) {
			try {
console.log('createLicencedUser()...');
				const response = await CallService.createLicencedUser({
					userId,
				});
console.log('response:', response);
			} catch(err) {
console.log('createLicencedUser():err:', err);
			}
		},
		async deleteLicencedUser(userId) {
			try {
				const response = await CallService.deleteLicencedUser(userId);
console.log('deleteLicencedUser():response:', response);
			} catch(err) {
console.log('deleteLicencedUser():err:', err);
			}
		},
		canHost(user) {
//			return this.licensedUsers.includes(user.email);
			console.log('zoomUserEmail:',user.userOrganisations[0].zoomUserEmail, this.licensedUsers);
			return this.licensedUsers.includes(user.userOrganisations[0].zoomUserEmail);
		},
/*		assignUsers(user) {
			const users = Array.isArray(user) ? user : [user];

			this.$store.dispatch('calls/assignUsers', {
				meetingNumber: this.previewCall.meetingNumber,
				users: users,
			});
		},
		unassignUsers(user) {
			const users = Array.isArray(user) ? user : [user];

			this.$store.dispatch('calls/unassignUsers', {
				meetingNumber: this.previewCall.meetingNumber,
				users: users,
			});
		},*/
		formatStartTime(date) {
			return dayjs(date).format('MMM D, YYYY / h:mma');
		},
		getCallRole(user) {
			const usr = this.modal.fields.attendees.find(u => u.id === user.id);
			return this.callRoleOpts.find((r) => r.value === usr.callRole);
		},
		setCallRole(selected, user) {
			const i = this.modal.fields.attendees.findIndex(u => u.id === user.id);
			this.modal.fields.attendees[i].callRole = selected.value;
			this.toggleTint(true);
		},
		assignUsers(user) {
			let users = Array.isArray(user) ? user : [user];

//			users = users.map(u => {
				// add new attribute
//				return {...u, callRole:this.callRoleDef.value};
//			});

			this.modal.fields.attendees.push(...users);
		},
		unassignUsers(user) {
			const users = Array.isArray(user) ? user : [user];
			const userIds = users.map(u => u.id);

			this.modal.fields.attendees = this.modal.fields.attendees.filter(u => !userIds.includes(u.id) ||  u.callRole === 'host');
		},
		endCall() {
			this.client.endMeeting(); // only host()
		},
		leaveCall() {
			this.client.leaveMeeting(); // works
			this.callActive = false;
		},
		getAttendees() { // works
			console.log('client.getAttendeeslist():', this.client.getAttendeeslist()); // works (empty list before call started)
		},
		toggleTint(bool) {
			this.$store.dispatch('gui/setTintState', bool);
		},
		resetModalFields() {
			this.modal.fields.topic = null;
			this.modal.fields.agenda = null;
			this.modal.fields.startDate = null;
			this.modal.fields.startTime = null;
			this.modal.fields.duration = this.durationOpts[3];
			this.modal.fields.muteOnEntry = false;
			this.modal.fields.attendees = [];
			this.modal.showAttendees = false;
			this.$refs.formCall.resetForm();
		},
		toggleModal(val) {
			if (val === false) {
				this.toggleTint(false);
				this.modal.show = false;
				setTimeout(() => {
					this.resetModalFields();
				}, 600);

			} else {
				this.toggleTint(true);
				this.modal.show = true;
			}
		},
		isHost(user) {
			const hostUser = this.modal.fields.attendees.find(u => u.callRole === 'host') || null;

			return (hostUser && user.id === hostUser.id);
		},
		addCall() {
			this.modal.add = true;
			this.resetModalFields();
			this.toggleModal(true);

			this.$nextTick(() => {
				// set auth user as host and add to attendees
				const user = this.filteredUsers.find(u => u.id === this.user.id);
				user.callRole = 'host';
				this.modal.fields.attendees.push(user);
			});
		},
		editCall(index) {
			this.modal.add = false;
			this.previewIndex = index;
			this.toggleModal(true);

			const assignedUserIds = this.previewCall.attendees.map(a => a.userId) || [];
			const attendees = this.filteredUsers.filter(u => assignedUserIds.includes(u.id));

			this.$nextTick(() => {
				this.modal.fields.topic = this.previewCall.topic;
				this.modal.fields.agenda = this.previewCall.agenda;
				this.modal.fields.startDate = this.previewCall.startDate;
				this.modal.fields.startTime = this.previewCall.startTime;
				this.modal.fields.attendees = attendees;
			});
		},
		async saveCall() {
//			this.modal.fields
//			console.log('saveCall:', values);
			try {
				await this.$store.dispatch('calls/dbAddCall', this.modal.fields);
				
			} catch(err) {
				console.log(err);
			}
		},
		async refreshCalls() {
			// zoom api response
			// load people from db
			await this.$store.dispatch('calls/dbCalls');
//			const {data} = await CallService.getMeetings();
//			data.meetings;
//console.log('meetings:', data.meetings);
		},
		async getSignature(call) {
			try {
				const response = await CallService.generateSignature({
					meetingNumber: call.meetingNumber,
					role: call.role,
				});

				this.initCall(call, response.data.signature);

			} catch(err) {
console.log('getSignature():err:', err);
			}
		},
		async initCall(call, signature) { // meeting or webinar
			try {
				this.toggleTint(true);
				this.callActive = true;

				this.client.init({
					debug: true,
					zoomAppRoot: document.getElementById('zoom'),
					language: 'en-GB',
					customize: {
						activeApps: {
							popper: {
								disableDraggable: true,
								placement: 'top',
							},
						},
						meeting: {
							popper: {
								disableDraggable: true,
								placement: 'left-end',
							},
						},
//						meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
						meetingInfo: ['topic', 'host', 'mn', 'participant', 'dc', 'enctype'],
						/*toolbar: {
							buttons: [
								{
									text: 'Custom Button',
									className: 'CustomButton',
									onClick: () => {
										console.log('custom button');
									},
								},
							],
						},*/
					},
				});

				this.client.join({
					sdkKey: this.sdkKey, // required
					signature: signature, // required, role in signature needs to be 1
					meetingNumber: call.meetingNumber,
					password: call.meetingPassword, // requried, empty string if meeting only requires waiting room
					userName: this.user.fullName, // requried
					userEmail: this.user.email, // required if meeting requires registration or a webinar
					// optional...
					//tk: registrantToken, // required (if meeting requires registration)
					//zak: zakToken // required to start meeting (the host's zak token)
				});

			} catch(error) {
console.log('initCall():error:', error);
			}
		},
	},
}
</script>

<style lang="scss">
#zoom {
	z-index: 140;
	position: absolute;
	top: 0;
	right: 0;
	pointer-events: none;
	>* {
		pointer-events: auto;
	}
	>div:first-of-type { // main zoom popup
		min-width: 600px;
		>div:first-of-type {
			>div.MuiPaper-elevation1 {
//			>div:first-of-type:not(:last-of-type) { // upper / lower toolbars
				width: 100%;
			}
		}
	}
	.full-view1 {
		height: auto;
		padding-top: $ratio_16x9;
		>div:last-of-type { // video wrapper
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			>li { // username overlay
				top: 0 !important;
				left: 0 !important;
				width: 100% !important;
				height: 100% !important;
			}
		}
	}
}
body {
	>[role="dialog"] { // zoom meeting info etc
		z-index: 140 !important;
	}
	#transition-popper { // confirm leave meeting button
		z-index: 140 !important;
	}
}
#calls {
	.ctrl {
		z-index: 1;
		position: sticky;
		top: 0;
		margin: 0 0 20px 0;
		background: $lightergrey;
		&:after { // scroll gradient
			content: '';
			pointer-events: none;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 20px;
			background-image: linear-gradient(0deg, transparent, $lightergrey);
		}
	}
	.wrap {
		display: flex;
		flex-wrap: wrap;
	}
	>.wrap {
		>.list {
			flex-grow: 1;
			min-width: 290px;
		}
	}
	.preview {
		width: 400px;
		border-radius: 8px;
		margin-left: 30px;
		overflow: hidden;
		background: #FFF;
		box-shadow: 0 5px 15px -5px rgba(#000, .2);
		align-self: flex-start; // removes full height
		.butmore {
			>:nth-child(1) {
				color: #FFF;
				&:not(:disabled):hover {
					background: #FFF;
					color: $black;
				}
			}
			>:nth-child(-n+2) {
				border-color: #FFF;
			}
			>:nth-child(2) {
				.fill {
					fill: #FFF,
				}
				&:not(:disabled):hover {
					background: #FFF;
					.fill {
						fill: $black;
					}
				}
			}
		}
		.but {
			&:not(:disabled):hover {
				background: #FFF;
				color: $black;
			}
		}
		table {
			tr {
				display: flex;
				flex-direction: column;
				&:last-of-type {
					td {
						margin-bottom: 0;
					}
				}
			}
			td {
				margin-bottom: 20px;
			}
		}
		.head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-height: 50px;
			padding: 15px 20px;
			background: $darkgrey;
			h4 {
				color: #FFF;
				font-weight: 500;
				font-size: 16px;
				text-transform: uppercase;
				margin: 0 20px 0 0;
			}
			h6 {
				color: #FFF;
				text-transform: uppercase;
			}
		}
		.body {
			position: relative;
			min-height: 180px;
			padding: 20px;
			.user {
				position: absolute;
				top: 20px;
				right: 20px;
			}
			hgroup {
				margin-bottom: 20px;
				h3 {
					font-size: 2rem;
					font-weight: 400;
					margin-bottom: 5px;
				}
				h4 {
					font-size: 1.4rem;
					font-weight: 500;
				}
			}
		}
	}
	.modal {
		display: flex;
		flex-direction: column;
//		height: 100%; // maybe?
		>form {
			&:after {
				bottom: 50px;
			}
		}
	}
	form {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 20px 0;
		max-height: none;
		overflow: hidden;
		.slider {
			display: flex;
			width: 200%;
			overflow: hidden;
			transition: .3s ease-in-out;
			&.show {
				transform: translateX(-50%);
			}
			>* {
				width: 50%;
				padding: 0 20px;
				overflow: scroll;
			}
		}
		.foot {
			padding: 0 20px;
		}
		.wrap {
			justify-content: space-between;
			>* {
				width: calc(50% - 10px);
			}
		}
		ul.list {
			&:before {
				height: 100%;
			}
			>li {
				padding-right: 20px;
			}
		}
		h6 {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			font-size: 1rem;
			font-weight: 600;
			color: #888888;
			text-transform: uppercase;
			order: -1;
			padding: 0 10px;
			margin-bottom: 10px;
		}
		p.host {
			font-size: 1.4rem;
			color: $orange;
		}
	}
}
</style>
